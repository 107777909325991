import addLog from "~/helpers/jLogs";
import { createInstance, TerminalEvent } from "@fra.me/terminal-factory";

export default function SessionApiWrapper(state, dispatch) {
  const handleStart = async () => {
    const userData = `{"name": "John Smith","user_id": "123456789X","token": "example.token.data"}`;

    let startData = {
      appId: state.sessionMode === "desktop" ? "" : state.appId,
      userData: userData || "",
    };

    if (!state.appId) {
      startData = { userData: userData };
      console.log(`Start Data: ${JSON.stringify(startData, null, 2)}`);
    }

    try {
      const session = await window.terminal.getOpenSession();
      if (session) {
        addLog({ message: "Resuming a  session" }, dispatch);
        await window.terminal.resume(session.id);
      } else {
        addLog({ message: "Starting a fresh session" }, dispatch);
        await window.terminal.start(startData);
      }
    } catch (err) {
      addLog({ message: `${err.message}` }, dispatch);
    }
  };

  const handleStop = async () => {
    try {
      await window.terminal.stop();
    } catch (err) {
      addLog({ message: `${err?.message}` }, dispatch);
    }
  };

  const handleDisconnect = async () => {
    try {
      await window.terminal.disconnect();
    } catch (err) {
      addLog({ message: `${err?.message}` }, dispatch);
    }
  };

  const handleResume = async () => {
    try {
      const session = await window.terminal.getOpenSession();
      if (session) {
        await window.terminal.resume(session.id);
      } else {
        addLog({ message: "Can't resume. No open session found." }, dispatch);
      }
    } catch (err) {
      addLog({ message: `${err.message}` }, dispatch);
    }
  };

  const handleGetOpenSession = async () => {
    try {
      const session = await window.terminal.getOpenSession();
      session
        ? addLog({ message: JSON.stringify(session, null, 2) }, dispatch)
        : addLog({ message: "No active session." }, dispatch);
    } catch (err) {
      addLog({ message: err.message, error: true }, dispatch);
    }
  };

  const handleGetActiveApplications = async () => {
    try {
      const activeAppInfo = await window.terminal.getActiveApplications();
      addLog(JSON.stringify(activeAppInfo), dispatch);
    } catch (err) {
      addLog({ message: err.message, error: true }, dispatch);
    }
  };

  const handleStartApplication = async () => {
    try {
      await window.terminal.startApplication(state.secondaryAppId);
    } catch (err) {
      addLog({ message: err.message, error: true }, dispatch);
    }
  };

  const handleCloseApplication = async () => {
    try {
      await window.terminal.closeApplication(state.secondaryAppId);
    } catch (err) {
      addLog({ message: err.message, error: true }, dispatch);
    }
  };

  const handleFocusApplication = async () => {
    try {
      await window.terminal.focusApplication(state.secondaryAppId);
    } catch (err) {
      addLog({ message: err.message, error: true }, dispatch);
    }
  };

  const loadTerminal = async () => {
    addLog({ message: "Initializing Frame." }, dispatch);
    dispatch({ type: "terminalLoading", value: true });
    if (state.terminalConfigId && state.token) {
      addLog({ message: "Loading configuration." }, dispatch);

      const terminalOptions = {
        serviceUrl: FRAME_ENVIRONMENTS[state.currentFrameEnvironment],
        terminalConfigId: state.terminalConfigId,
        token: state.token,
      };

      try {
        window.terminal = await createInstance(terminalOptions);
        bindEvents(window.terminal);
        dispatch({ type: "terminalLoading", value: false });
        dispatch({ type: "terminalLoaded", value: true });
        addLog({ message: "Terminal instantiated." }, dispatch);
      } catch (err) {
        addLog({ message: `${err.message}`, error: true }, dispatch);
        dispatch({ type: "resetTerminal" });
      }
    }

    function bindEvents(terminal) {
      terminal.bind(TerminalEvent.SESSION_STARTING, () => {
        addLog({ message: "Starting session! Please wait..." }, dispatch);
        dispatch({ type: "terminalLoading", value: true });
      });

      terminal.bind(TerminalEvent.SESSION_STARTED, () => {
        addLog({ message: "Session started!" }, dispatch);
        dispatch({ type: "terminalLoading", value: false });
        document.getElementsByTagName("body")[0].style.overflow = "visible";
      });

      terminal.bind(TerminalEvent.SESSION_RESUMING, () => {
        addLog({ message: "Resuming session!" }, dispatch);
        dispatch({ type: "terminalLoading", value: true });
      });

      terminal.bind(TerminalEvent.SESSION_RESUMED, () => {
        addLog({ message: "Session resumed!" }, dispatch);
        dispatch({ type: "terminalLoading", value: false });
        document.getElementsByTagName("body")[0].style.overflow = "visible";
      });

      terminal.bind(TerminalEvent.SESSION_CLOSING, () => {
        addLog({ message: "Closing session!" }, dispatch);
      });

      terminal.bind(TerminalEvent.SESSION_CLOSED, () => {
        addLog({ message: "Session closed!" }, dispatch);
        dispatch({ type: "resetTerminal" });
      });

      terminal.bind(TerminalEvent.SESSION_DISCONNECTED, () => {
        addLog({ message: "Disconnected! Session still running." }, dispatch);
      });
    }
  };

  const destroyTerminal = async () => {
    try {
      window.terminal.destroy();
      addLog({ message: "Terminal destroyed." }, dispatch);
      dispatch({ type: "resetTerminal" });
    } catch (err) {
      addLog({ message: `Message: ${err.message}` }, dispatch);
    }

    dispatch({ type: "terminalLoaded", value: false });
  };

  const getTokenData = (token) => {
    try {
      return JSON.stringify(JSON.parse(atob(token.split(".")[1])), null, 2);
    } catch (e) {
      return null;
    }
  };

  return {
    handleStart,
    handleStop,
    handleDisconnect,
    handleResume,
    handleGetOpenSession,
    handleGetActiveApplications,
    handleStartApplication,
    handleCloseApplication,
    handleFocusApplication,
    loadTerminal,
    destroyTerminal,
    getTokenData,
  };
}

export const FRAME_ENVIRONMENTS = {
  PRODUCTION: "https://cpanel-backend.console.nutanix.com/api/graphql",
  STAGING: "https://cpanel-backend.staging.console.nutanix.com/api/graphql",
};
