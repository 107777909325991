const CodeExamples = {
  TERMINAL_START: {
    title: "Terminal.start",
    description:
      "Initiates the start of a session. This example checks for an existing session to resume before attempting to start one fresh.",
    buttonText: "terminal.start()",
    codeExample: `
  const startButton = document.getElementById("start-button");
  startButton.addEventListener("click", () => handleStart());

  const handleStart = async () => {
    try {
      const session = await terminal.getOpenSession();
      if (session) {
        await terminal.resume(session.id);
      } else {
        await terminal.start({appId: applicationId});
      }
    } catch(e) {
      addLog(e, "error");
    }
  };

`,
  },
  TERMINAL_STOP: {
    title: "Terminal.stop",
    description:
      "Stops the session immediately. The session is closed and cannot be resumed.",
    buttonText: "terminal.stop()",
    codeExample: `
  const stopButton = document.getElementById("stop-button");

  stopButton.addEventListener("click", async () => {
    try {
      await terminal.stop()
    } catch (e) {
      addLog(e, "error");
    }
  });

`,
  },
  TERMINAL_RESUME: {
    title: "Terminal.resume",
    description:
      "Resumes a session based on a given session ID. Use getOpenSession to get an open session object, including it's session ID.",
    buttonText: "terminal.resume(session.id)",
    codeExample: `
  const resumeButton = document.getElementById("resume-button");

  resumeButton.addEventListener("click", async () => {
    try {
      const session = await terminal.getOpenSession();
      await terminal.resume(session.id)
    } catch (e) {
      addLog(e, "error");
    }
  });

`,
  },
  TERMINAL_DISCONNECT: {
    title: "Terminal.disconnect",
    description:
      "Disconnects a live session. Once disconnected, the session can be resumed by that same user on any device.",
    buttonText: "terminal.disconnect()",
    codeExample: `
  const disconnectButton = document.getElementById("disconnect-button");

  disconnectButton.addEventListener("click", async () => {
    try {
      await terminal.disconnect()
    } catch (e) {
      addLog(e, "error");
    }
  });

`,
  },
  TERMINAL_GET_OPEN_SESSION: {
    title: "Terminal.getOpenSession",
    description:
      "Returns a Session object if there is an currently open session for the user.",
    buttonText: "terminal.getOpenSession()",
    codeExample: `
  const getOpenSessionButton = document.getElementById("get-open-session-button");
  getOpenSessionButton.addEventListener("click", () => handleGetOpenSession());
  
  const handleGetOpenSession = async () => {
    try {
      const session = await terminal.getOpenSession();
      if (session) {
        console.log(JSON.stringify(session, null, 2))
      } else {
        console.log("Open session not found for this user.")
      }
    } catch(e) {
      addLog(e, "error");
    }
  };

`,
  },
  TERMINAL_GET_ACTIVE_APPLICATIONS: {
    title: "Terminal.getActiveApplications",
    description:
      "Returns a list of active/running applications within the session. Each app in the returned array hsa a number of useful properties, including the app name and icon URL.",
    buttonText: "terminal.getActiveApplications()",
    codeExample: `
  const getActiveAppsButton = document.getElementById("active-applications-button");
  getActiveAppsButton.addEventListener("click", () => handleGetActiveApps());
  
  const handleGetActiveApps = async () => {
    try {
      const activeAppInfo = await terminal.getActiveApplications();
      console.log(JSON.stringify(activeAppInfo, null, 2))
    } catch(e) {
      addLog(e, "error");
    }
  };

`,
  },
  TERMINAL_START_APPLICATION: {
    title: "Terminal.startApplication",
    description:
      "Starts an application in the VM environment based on it's Frame app ID (uuid format).",
    buttonText: "terminal.startApplication(appId)",
    codeExample: `
  const startAppButton = document.getElementById("start-application");
  startAppButton.addEventListener("click", () => handleStartApplication());
  
  const handleStartApplication = async () => {
    try {
      await terminal.startApplication(applicationId);
    } catch(e) {
      addLog(e, "error");
    }
  };

`,
  },
  TERMINAL_CLOSE_APPLICATION: {
    title: "Terminal.closeApplication",
    description:
      "Immediately close an application based on it's Frame app ID (uuid format).",
    buttonText: "terminal.closeApplication(appId)",
    codeExample: `
  const closeApplicationButton = document.getElementById("close-application-button");
  closeApplicationButton.addEventListener("click", () => handleCloseAppButton());
  
  const handleCloseAppButton = async () => {
    try {
      await terminal.closeApplication(applicationId);
    } catch(e) {
      addLog(e, "error");
    }
  };

`,
  },
  TERMINAL_FOCUS_APPLICATION: {
    title: "Terminal.focusApplication",
    description:
      "Brings an actively running app to focus (in front of all other running apps).",
    buttonText: "terminal.focusApplication(appId)",
    codeExample: `
  const focusAppButton = document.getElementById("focus-app-button");
  focusAppButton.addEventListener("click", () => handleFocusApplication());

  const handleFocusApplication = async () => {
    try {
      await terminal.focusApplication(applicationId)
    } catch(e) {
      addLog(e, "error");
    }
  };

`,
  },
};

export default CodeExamples;
