import React, { useReducer, useEffect, useRef } from "react";
// import useLocalStorage from "~/helpers/localStorage";
import useLocalReducer from "~/helpers/localReducer";
import addLog from "~/helpers/jLogs";
import SessionApiWrapper from "~/helpers/sessionApiWrapper";

// import ExampleCodeBox from "~/components/ExampleCodeBox/ExampleCodeBox";
// import CodeExamples from "./CodeExamples";
import {
  Button,
  Divider,
  FlexLayout,
  FormItemInput,
  Title,
  Dashboard,
  RadioGroup,
  Radio,
  // Link,
  Loader,
  Menu,
  MenuItem,
  MenuGroup,
  // OrderedList,
  DashboardWidgetLayout,
  DashboardWidgetHeader,
  StackingLayout,
  TextLabel,
  VerticalSeparator,
} from "@nutanix-ui/prism-reactjs";
import ExampleCodeBox from "../ExampleCodeBox/ExampleCodeBox";
import CodeExamples from "./CodeExamples";

import { motion } from "framer-motion";

function appReducer(state, action) {
  switch (action.type) {
    case "terminalConfigId":
      return {
        ...state,
        terminalConfigId: action.value,
      };
    case "token":
      return {
        ...state,
        token: action.value,
      };
    case "appId":
      return {
        ...state,
        appId: action.value,
      };
    case "secondaryAppId":
      return {
        ...state,
        secondaryAppId: action.value,
      };
    case "currentFrameEnvironment":
      return {
        ...state,
        currentFrameEnvironment: action.value,
      };
    case "sessionMode":
      return {
        ...state,
        sessionMode: action.value,
      };
    default:
      throw new Error();
  }
}

function sessionReducer(state, action) {
  switch (action.type) {
    case "menuPath":
      return {
        ...state,
        menuPath: action.value,
      };
    case "examplePage":
      console.log("Setting example page...", action.value);
      return {
        ...state,
        examplePage: action.value,
      };
    case "paramsReady":
      return {
        ...state,
        paramsReady: action.value,
      };
    case "terminalLoaded":
      return {
        ...state,
        terminalLoaded: !state.terminalLoaded,
      };
    case "terminalLoading":
      return {
        ...state,
        terminalLoading: !state.terminalLoading,
      };
    case "addLog":
      return {
        ...state,
        logs: [action.log, ...state.logs],
      };
    case "resetTerminal":
      return {
        ...state,
        terminalLoading: false,
        terminalLoaded: true,
      };
    default:
      throw new Error();
  }
}

export default function SessionApiPlayground() {
  const initialAppState = {
    terminalConfigId: undefined,
    token: undefined,
    appId: undefined,
    secondaryAppId: undefined,
    currentFrameEnvironment: "PRODUCTION",
    sessionMode: "desktop",
  };

  const initialSessionState = {
    logs: [],
    paramsReady: false,
    terminalLoaded: false,
    terminalLoading: false,
    menuPath: ["general", "start"],
    examplePage: "start",
  };

  const [appState, appDispatch] = useLocalReducer(
    appReducer,
    initialAppState,
    "api-playground"
  );

  const [sessionState, sessionDispatch] = useReducer(
    sessionReducer,
    initialSessionState
  );

  const {
    handleStart,
    handleStop,
    handleDisconnect,
    handleResume,
    handleGetOpenSession,
    loadTerminal,
    destroyTerminal,
    getTokenData,
  } = SessionApiWrapper(appState, sessionDispatch);

  const {
    terminalConfigId,
    appId,
    token,
    currentFrameEnvironment,
    sessionMode,
  } = appState;

  const { terminalLoaded, terminalLoading, paramsReady } =
    sessionState || undefined;

  useEffect(() => {
    const togglePlayerButton = document.getElementById(
      "terminal-bar-player-toggle"
    );

    let isDragging = false,
      startX,
      startY;

    togglePlayerButton.addEventListener(
      "mousedown",
      (e) => ((isDragging = false), (startX = e.clientX), (startY = e.clientY))
    );
    togglePlayerButton.addEventListener(
      "mousemove",
      (e) =>
        (isDragging |= Math.hypot(e.clientX - startX, e.clientY - startY) > 5)
    );
    togglePlayerButton.addEventListener("mouseup", (e) => {
      e.preventDefault();
      if (!isDragging) {
        const potentialTerminalElement = document.querySelectorAll(
          "[data-selector='frame-terminal-container']"
        )[0];
        if (potentialTerminalElement) {
          potentialTerminalElement.classList.toggle("active-session");
        } else {
          addLog(
            {
              message:
                "Terminal iframe not present. Wait until you connect to a session and try again.",
            },
            sessionDispatch
          );
        }
      }
    });
    addLog(
      { message: "Playground is fully loaded. Welcome!" },
      sessionDispatch
    );
    checkParams();
  }, []);

  useEffect(() => {
    console.log(
      "App state changed... checking params...",
      terminalConfigId,
      token
    );
    /** check if session params are present and ready to create a terminal instance */
    checkParams();
  }, [appState]);

  function checkParams() {
    if (sessionMode == "desktop") {
      if (terminalConfigId && token) {
        sessionDispatch({ type: "paramsReady", value: true });
      } else {
        sessionDispatch({ type: "paramsReady", value: false });
      }
    } else {
      if (terminalConfigId && token && appId) {
        sessionDispatch({ type: "paramsReady", value: true });
      } else {
        sessionDispatch({ type: "paramsReady", value: false });
      }
    }
  }

  const DashboardLayout = {
    layouts: {
      lg: [
        {
          i: "session-requirements",
          x: 0,
          y: 0,
          w: 4,
          h: 1,
        },
        {
          i: "session-controls",
          x: 0,
          y: 1,
          w: 4,
          h: 2,
        },
        {
          i: "session-logs",
          x: 0,
          y: 1,
          w: 4,
          h: 1,
        },
      ],
    },
  };

  // const requirementsData = [
  //   "Frame Environment URI. This URI points to which Frame environment your resources reside.",
  //   "Terminal configuration Id. You get this ID once you've configured a Launchpad. Visit Launchpad -> Session API Integrations -> Select your Terminal Configuration ID based on your desired instance type.",
  //   <span>
  //     A valid JWT (JSON Web Token). Need help? Visit our authentication section
  //     of our <Link style={{ display: "inline" }}>Frame Documentation</Link>.
  //   </span>,
  // ];

  const SessionComponents = () => (
    <FlexLayout flexDirection="row" flexGrow={1} alignItems="center">
      <StackingLayout
        itemSpacing="0px"
        padding="20px"
        style={{ "flex-grow": "1" }}
      >
        {/* <StackingLayout>
          <Title size="h3">Prerequisites</Title>
          <OrderedList data={requirementsData} />
        </StackingLayout> */}
        <StackingLayout itemSpacing="0px">
          <FlexLayout
            justifyContent="flex-start"
            itemSpacing="20px"
            flexDirection="row"
            alignItems="center"
            padding="20px"
          >
            <TextLabel>Frame Environment</TextLabel>
            <RadioGroup
              name="FrameEnvironmentSelector"
              layout={RadioGroup.LAYOUT.HORIZONTAL}
              onChange={(value) =>
                appDispatch({ type: "currentFrameEnvironment", value: value })
              }
              selectedValue={currentFrameEnvironment}
            >
              <Radio
                disabled={terminalLoading || terminalLoaded}
                value="PRODUCTION"
                additionalInfo="Default"
              >
                Production
              </Radio>
              <Radio
                disabled={terminalLoading || terminalLoaded}
                value="STAGING"
              >
                Staging
              </Radio>
            </RadioGroup>
            <VerticalSeparator size="large" />
            <TextLabel>Session Mode</TextLabel>
            <SessionModeSelector />
          </FlexLayout>
        </StackingLayout>
        <FlexLayout
          justifyContent="flex-start"
          itemSpacing="20px"
          itemFlexBasis="100pc"
          padding="20px"
        >
          <FormItemInput
            id="session-params-terminal-config-id"
            label="Terminal Config ID"
            disabled={terminalLoading || terminalLoaded}
            onChange={(e) =>
              appDispatch({ type: "terminalConfigId", value: e.target.value })
            }
            defaultValue={terminalConfigId}
          />
          <FormItemInput
            id="session-params-token"
            label="Token"
            disabled={terminalLoading || terminalLoaded}
            placeholder="Paste JWT here"
            defaultValue={token}
            onChange={(e) =>
              appDispatch({ type: "token", value: e.target.value })
            }
          />
        </FlexLayout>
      </StackingLayout>
      <StackingLayout
        style={{
          "border-left": "1px #f2f4f6 solid",
          "flex-grow": "1",
          height: "300px;",
        }}
      >
        <DashboardWidgetLayout
          header={
            <DashboardWidgetHeader
              style={{
                "justify-content": "center",
                "align-items": "center",
              }}
              title={<Title size="h2">Blast off zone</Title>}
              showCloseIcon={false}
            />
          }
          bodyContent={
            <FlexLayout
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              flexGrow={1}
            >
              <Loader loading={terminalLoading} tip="Loading..." />
              {!terminalLoaded ? (
                <Button
                  disabled={!paramsReady || terminalLoading || terminalLoaded}
                  onClick={() => loadTerminal()}
                >
                  Load Terminal
                </Button>
              ) : (
                <Button type="destructive" onClick={() => destroyTerminal()}>
                  Destroy Terminal
                </Button>
              )}
              <Button
                disabled={terminalLoaded || !token}
                onClick={() => redirectToLaunchpad()}
              >
                Use token with Launchpad
              </Button>
            </FlexLayout>
          }
        />
      </StackingLayout>
    </FlexLayout>
  );

  const redirectToLaunchpad = () => {
    const newWindow = window.open(
      `https://console.nutanix.com/#token=${token}`,
      `_blank`
    );
    newWindow.focus();
  };

  const renderExampleByName = (name) => {
    switch (name) {
      case "start":
        return (
          <ExampleCodeBox
            {...CodeExamples.TERMINAL_START}
            clickHandler={handleStart}
            disabled={!paramsReady || !terminalLoaded || terminalLoading}
          />
        );
      case "stop":
        return (
          <ExampleCodeBox
            {...CodeExamples.TERMINAL_STOP}
            clickHandler={handleStop}
            disabled={!paramsReady || !terminalLoaded || terminalLoading}
          />
        );
      case "disconnect":
        return (
          <ExampleCodeBox
            {...CodeExamples.TERMINAL_DISCONNECT}
            clickHandler={handleDisconnect}
            disabled={!paramsReady || !terminalLoaded || terminalLoading}
          />
        );
      case "resume":
        return (
          <ExampleCodeBox
            {...CodeExamples.TERMINAL_RESUME}
            clickHandler={handleResume}
            disabled={!paramsReady || !terminalLoaded || terminalLoading}
          />
        );
      case "getOpenSession":
        return (
          <ExampleCodeBox
            {...CodeExamples.TERMINAL_GET_OPEN_SESSION}
            clickHandler={handleGetOpenSession}
            disabled={!paramsReady || !terminalLoaded || terminalLoading}
          />
        );
      default:
        return "Placeholder...";
        break;
    }
  };

  const handleExampleMenuClick = (menuAction) => {
    sessionDispatch({ type: "menuPath", value: menuAction.keyPath });
  };

  const SessionModeSelector = () => {
    return (
      <FlexLayout justifyContent="space-between" alignItems="center">
        <RadioGroup
          name="SessionModeSelector"
          label="Session Mode"
          layout={RadioGroup.LAYOUT.HORIZONTAL}
          onChange={(value) =>
            appDispatch({ type: "sessionMode", value: value })
          }
          selectedValue={sessionMode || "desktop"}
        >
          <Radio
            disabled={terminalLoading || terminalLoaded}
            value="desktop"
            additionalInfo="Run all jobs in parallel."
          >
            Desktop
          </Radio>
          <Radio disabled={terminalLoading || terminalLoaded} value="app">
            Application
          </Radio>
        </RadioGroup>
        {sessionMode === "app" && (
          <>
            <FormItemInput
              id="session-params-session-mode"
              value={appState.appId}
              onChange={(e) =>
                appDispatch({ type: "appId", value: e.target.value })
              }
              disabled={
                sessionMode == "desktop" || terminalLoading || terminalLoaded
              }
              placeholder="Paste your Application ID here"
              style={{ width: "300px" }}
            />
            <FormItemInput
              id="session-params-session-mode"
              value={appState.secondaryAppId}
              onChange={(e) =>
                appDispatch({ type: "secondaryAppId", value: e.target.value })
              }
              disabled={sessionMode == "desktop" || terminalLoading}
              placeholder="Optional Secondary App ID"
              style={{ width: "300px" }}
            />
          </>
        )}
      </FlexLayout>
    );
  };

  const constraintsRef = useRef(null);

  return (
    <motion.div ref={constraintsRef}>
      <Dashboard {...DashboardLayout}>
        <div key="session-requirements">
          <DashboardWidgetLayout
            header={
              <DashboardWidgetHeader
                title={<Title size="h2">Terminal & Session Requirements</Title>}
                // supplementaryInfo="These fields are required to start a session."
                showCloseIcon={false}
              />
            }
            bodyContent={<SessionComponents />}
          />
        </div>
        <div key="session-controls">
          <DashboardWidgetLayout
            header={
              <DashboardWidgetHeader
                title={<Title size="h2">Terminal Session Methods</Title>}
                showCloseIcon={false}
              />
            }
            bodyContent={
              <FlexLayout
                itemSpacing="0px"
                className="nsg-example-borderless-content"
                flexGrow={1}
                padding="0px 20px"
              >
                <Menu
                  itemSpacing="20px"
                  padding="20px-0px"
                  activeKeyPath={sessionState.menuPath}
                  onClick={handleExampleMenuClick}
                  style={{
                    width: "240px",
                    minWidth: "240px",
                  }}
                >
                  <MenuGroup key="general" title="General Session Controls">
                    <MenuItem key="start">start</MenuItem>
                    <MenuItem key="stop">stop</MenuItem>
                    <MenuItem key="resume">resume</MenuItem>
                    <MenuItem key="disconnect">disconnect</MenuItem>
                    <MenuItem key="getOpenSession">getOpenSession</MenuItem>
                  </MenuGroup>
                  <StackingLayout padding="0px-20px">
                    <Divider type="short" />
                  </StackingLayout>
                </Menu>

                <FlexLayout flexGrow="1" padding="20px">
                  {renderExampleByName(sessionState.menuPath[1])}
                </FlexLayout>
              </FlexLayout>
            }
          />
        </div>
        <div key="session-logs">
          <DashboardWidgetLayout
            header={
              <DashboardWidgetHeader
                title={<Title size="h2">Session Logs</Title>}
                showCloseIcon={false}
              />
            }
            bodyContent={
              <ul id="logs">
                {sessionState.logs &&
                  sessionState.logs.map((log) => (
                    <li className={log.error ? "error" : ""}>{log?.message}</li>
                  ))}
              </ul>
            }
            bodyContentProps={{ overflow: "auto" }}
          />
        </div>
      </Dashboard>
      <div className="tutorial-controls" whileHover={{ scale: 1.5 }}>
        <motion.button
          drag
          drag
          dragElastic={0.01}
          dragMomentum={false}
          dragConstraints={constraintsRef}
          id="terminal-bar-player-toggle"
        >
          <br />
          TOGGLE <br /> FRAME
          <br />
          PLAYER
          <br />
          <div>:::::</div>
        </motion.button>
      </div>
    </motion.div>
  );
}
