function addLog(logObject, dispatch) {
  const log = { message: logObject.message, type: logObject.type };

  // const log = document.createElement("li");
  const timeNow = new Date();
  const hours = timeNow.getHours();
  const minutes = timeNow.getMinutes();
  const seconds = timeNow.getSeconds();
  let timestamp = "" + (hours > 12 ? hours - 12 : hours);
  timestamp += (minutes < 10 ? ":0" : ":") + minutes;
  timestamp += (seconds < 10 ? ":0" : ":") + seconds;
  timestamp += hours >= 12 ? " P.M." : " A.M.";

  if (typeof message === "object") {
    log.message = JSON.stringify(message, null, 2);
  }

  log.message = `${timestamp} - ${log.message}`;
  console.log(log.message);
  dispatch && dispatch({ type: "addLog", log: log });
}

export default addLog;
