import { useReducer, useEffect, Dispatch } from "react";

// Credits: https://stackoverflow.com/questions/54346866/save-to-localstorage-from-reducer-hook

let dispatcher;

const LOCAL_STORAGE_UPDATED_IN_A_DIFFERENT_WINDOW =
  "LOCAL_STORAGE_UPDATED_IN_A_DIFFERENT_WINDOW";

export default function useLocalReducer(
  reducer,
  defaultState,
  storageKey,
  init
) {
  const reducerWrapper = (status, action) => {
    switch (action.type) {
      case LOCAL_STORAGE_UPDATED_IN_A_DIFFERENT_WINDOW:
        const statusReadFromLocalStorage = JSON.parse(
          localStorage.getItem(storageKey) || "{}"
        );
        return statusReadFromLocalStorage;
      default:
        return reducer(status, action);
    }
  };

  const [state, dispatch] = useReducer(
    reducerWrapper,
    defaultState,
    (defaultState) => {
      const persisted = JSON.parse(localStorage.getItem(storageKey));
      return persisted !== null
        ? persisted
        : init
        ? init(defaultState)
        : defaultState;
    }
  );

  // NOTE Even if this codes runs multiple time we add only one event listener as long as the callback is singleton
  dispatcher = dispatch;
  window.addEventListener("storage", listenForStorageChangesInOtherWindows, {
    once: true,
  });

  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(state));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage, storageKey, state]);

  return [state, dispatch];
}

const listenForStorageChangesInOtherWindows = (key) => {
  dispatcher &&
    dispatcher({ type: LOCAL_STORAGE_UPDATED_IN_A_DIFFERENT_WINDOW });
};
