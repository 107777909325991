import React from "react";
import ReactDOM from "react-dom";
import {
  MainPageLayout,
  NavBarLayout,
  Link,
  TextLabel,
} from "@nutanix-ui/prism-reactjs";

import SessionApiPlayground from "./components/SessionApiPlayground/SessionApiPlayground";

import FrameLogo from "./assets/2022_frame_logo_white.svg";

import "highlight.js/styles/tomorrow-night.css";

import "@nutanix-ui/prism-reactjs/dist/index.css";
import "./assets/app.css";

const App = () => {
  return (
    <MainPageLayout
      style={{ "z-index": "unset" }}
      header={
        <NavBarLayout
          logoIcon={<img src={FrameLogo} height="24px" alt="Frame" />}
          layout={NavBarLayout.LAYOUTS.LEFT}
          accountInfo={
            <>
              <Link type="info" href="https://docs.fra.me/dev-hub/session-api/">
                Documentation
              </Link>
              <Link
                type="info"
                href="https://docs.fra.me/solution-guides/intro-to-session-api-pt-1"
              >
                Intro to Session API
              </Link>
              <TextLabel type="secondary">Created with ♥ by</TextLabel>
              <Link
                type="info"
                href="mailto:jason@fra.me?subject=About+Frame+API+Playground"
              >
                Jason Thompson
              </Link>
            </>
          }
          title="Session API Playground"
        />
      }
      fullPage={true}
      body={<SessionApiPlayground />}
    />
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
