import React, { useState } from "react";

import {
  Button,
  Divider,
  FlexLayout,
  Title,
  StackingLayout,
  TextLabel,
} from "@nutanix-ui/prism-reactjs";

import Highlight from "react-highlight";

import "highlight.js/styles/tomorrow-night.css";

function ExampleCodeBox(props) {
  return (
    <FlexLayout flexGrow={1}>
      <StackingLayout style={{ width: "400px" }}>
        <FlexLayout
          justifyContent="flex-start"
          alignItems="center"
          padding="5px"
        >
          <Title size="h3">{props.buttonText}</Title>
        </FlexLayout>
        <Divider />
        <FlexLayout
          justifyContent="center"
          alignItems="center"
          itemSpacing="20px"
          padding="10px"
          flexDirection="column"
        >
          <TextLabel type="secondary" size="MEDIUM">
            {props.description}
          </TextLabel>
          <Button onClick={props.clickHandler} disabled={props.disabled}>
            {"Run " + props.buttonText}
          </Button>
        </FlexLayout>
      </StackingLayout>
      <StackingLayout flexGrow={1} style={{ width: "100%" }}>
        <FlexLayout
          justifyContent="flex-start"
          alignItems="center"
          padding="5px"
        >
          <Title size="h3">Example code</Title>
        </FlexLayout>
        <Divider />
        <FlexLayout
          justifyContent="center"
          alignItems="flex-start"
          className="js-highlight"
        >
          <Highlight className="javascript">{`${props.codeExample}`}</Highlight>
        </FlexLayout>
      </StackingLayout>
    </FlexLayout>
  );
}

export default ExampleCodeBox;
